import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Image from "components/UI/image";

import Dropdown from "../dropdown";


export const categoryDropdownBannerQuery = graphql`
query CategoryDropdownBannerQuery {
  allContentfulBannerUpdated(filter: {type: {eq: "dropdown-percent"}}) {
    nodes {
      title
      type
      location
      image {
        title
        file {
          url
          contentType
        }
        gatsbyImageData(
          placeholder: NONE,
          formats: [AUTO, WEBP, AVIF],
        )
      }
      imageMobile {
        title
        title
        file {
          url
          contentType
        }
        gatsbyImageData(
          placeholder: NONE,
          formats: [AUTO, WEBP, AVIF],
        )
      }
      bulletListText {
        id
        dropdownTitle
        text
        richText {
          raw
        }
      }
      text {
        raw
      }
    }
  }
}
`;

const ParentCategoryDropdown = (props) => {
    const { categoryName } = props;

    const { allContentfulBannerUpdated } = useStaticQuery(categoryDropdownBannerQuery);
    const banner = allContentfulBannerUpdated.nodes.filter(banner => banner.location === `category-page ${categoryName}`);

    if (!banner[0]) {
        return <></>;
    }

    const { title, text, image, imageMobile, bulletListText } = banner[0];

    return (
        <div className="homepage__dropdown-banner category-page__dropdown">
            <div className="homepage__dropdown-banner__content category-page__dropdown-content">
                <h2 className="homepage__dropdown-banner__content-title typography__h2 category-page__dropdown-title">
                    {title}
                </h2>
                {text &&
                    <p className="category-page__dropdown-text">
                        {renderRichText(text)}
                    </p>
                }

                <Image image={image} imageMobile={imageMobile} className="dropdown-chart__image mobile mb-20"/>
                <Dropdown items={bulletListText}/>
            </div>
            <Image image={image} className="category-page__dropdown-image desktop"/>
        </div>
    );
};
export default ParentCategoryDropdown;
