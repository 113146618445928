import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import HeroBanner from 'components/UI/heroBanner';

import "styles/parent-category.scss";

export const parentCategoryHeroQuery = graphql`
query ParentCategoryHeroQuery {
  allContentfulBannerUpdated(
    filter: {type: {eq: "hero"}}
  ) {
    nodes {
      id
      title
      type
      location
      plainText
      sortOrder
      location
      type
      image {
        title
        file {
          url
          contentType
        }
        gatsbyImageData(
          placeholder: NONE,
          formats: [AUTO, WEBP, AVIF],
        )
      }
      imageMobile {
        title
        file {
          url
          contentType
        }
        gatsbyImageData(
          placeholder: NONE,
          formats: [AUTO, WEBP, AVIF],
        )
      }
      link {
        text
        type
        href
        isArrowShow
      }
      text {
        raw
      }
      name
      contentfulid
      contentful_page {
        contentfulid
        name
      }
    }
  }
}
`;

const ParentCategoryHero = (props) => {
    const { categoryName } = props;

    const { allContentfulBannerUpdated } = useStaticQuery(parentCategoryHeroQuery);
    const banners = allContentfulBannerUpdated.nodes;

    const heroBanner = banners.filter(banner => banner.location === `category-page ${categoryName}`)[0];

    if (!heroBanner) {
        return <></>
    }

    return (
        <HeroBanner {...heroBanner} type="category-page"/>
    );
};

export default ParentCategoryHero;
