import React from "react";

import Dropdown from "components/UI/dropdown";


const ParentCategoryDropdownFAQ = (props) => {
    const { banner } = props;

    return (
        <div className="homepage__dropdown-banner category-page__dropdown dropdown-faq__content">
            <h2 className="typography__h2 dropdown-faq__title">
                {banner.title}
            </h2>
            <Dropdown items={banner.bulletListText}/>
        </div>
    );
};
export default ParentCategoryDropdownFAQ;
