import React from "react";

import Image from "components/UI/image";

import "styles/homepage.scss";

const FAQItem = (props) => {
    const { slideImage, title, subtext, label } = props;

    return (
        <div className="FAQ-slide">
            <div className="FAQ-slide__image-container">
                <Image image={slideImage} className="FAQ-slide__image"/>
            </div>
            <div>
                <p className="FAQ-slide__title typography__title">{title}</p>
                <p className="FAQ-slide__subtext typography__p">{subtext}</p>
            </div>
        </div>
    );
};

export default FAQItem;
