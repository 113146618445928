import React, { useEffect } from "react";
import { useStaticQuery } from "gatsby";

import { useCurrency } from "context/currencyContext";
import { useCategories } from "context/categoriesContext";
import { useBrands } from "context/brandsContext";

import { isNotEmpty } from "utils/helper";

import Main from "components/Layout/main";
import QuoteSlider from "components/UI/quoteSlider";
import ProductSlider from "components/UI/productSlider";
import ParentCategoryHero from "components/UI/Listpage/parentCategoryHero";
import HomepageBottomBanner from "components/UI/Homepage/homepageBottomBanner";
import ParentCategoryDropdown from "components/UI/Listpage/parentCategoryDropDown";
import ParentCategoryDropdownFAQ from "components/UI/Listpage/parentCategoryDropDownFAQ";
import ParentCategoryDropdownChart from "components/UI/Listpage/parentCategoryDropDownChart";
import FAQSlider from "components/UI/FAQSlider";
import RoundImageBanner from "components/UI/roundImageBanner";
import HtmlHead from "components/UI/htmlHead";
import BlogSlider from "components/UI/blogSlider";
import Image from "components/UI/image";
import StaticPageMeta from "components/UI/staticPageMeta";
import ListpageContent, { listpageQuery } from "components/UI/Listpage/listpageContent";

import "styles/listpage.scss";
import "styles/homepage.scss";

import { GTMSelectItemEvent, GTMViewItemListEvent } from "components/GTM/gtmCategory";

import { HAIR_LOST, TOP_PRODUCTS } from "context/categoriesContext";

const Category = (props) => {
    const { pageContext } = props;

    const { categoryId, allContentfulPromoBanner, categoryCode } = pageContext;

    const queryData = useStaticQuery(listpageQuery);

    const products = queryData.allBigCommerceProducts.nodes;
    const categoryProducts = products.filter((product) =>
        product?.categories.includes(categoryId)
    );

    let title;
    if (categoryCode === HAIR_LOST)
        title = "Hair";
    else if (categoryCode === TOP_PRODUCTS)
        title = "Top products";

    let categoryTitle;
    if (categoryCode === HAIR_LOST)
        categoryTitle = "Haarverlies";
    else if (categoryCode === TOP_PRODUCTS)
        categoryTitle = "Populaire behandelingen";

    const sliders = queryData.allContentfulSlider.edges;
    const FAQSliderData = sliders.filter(
        (slider) =>
            slider.node.type === "science" && slider.node.location === `category-page ${title}`,
    );
    const banners = queryData.allContentfulBannerUpdated.edges;

    const [productsSliderData] = banners.filter(
        (banner) =>
            banner.node.type === "products" && banner.node.location === `category-page ${title}`
    );
    const currentPromoBanner = allContentfulPromoBanner?.nodes.find(el=> el.location === categoryTitle)


    const FAQSliderConfig = {
        spaceBetween: 20,
        slidesPerView: 1.25,
        pagination: {
            clickable: true,
            dynamicBullets: true,
            bulletClass: "swiper-pagination-bullet",
            bulletActiveClass: "swiper-pagination-bullet-active",
        },
        navigation:{
            nextEl: ".button-next-slide",
            prevEl: ".button-prev-slide",
        },
        breakpoints: {
            440: {
                slidesPerView: 1.5,
            },
            540: {
                slidesPerView: 1.75,
            },
            640: {
                slidesPerView: 2.15,
                spaceBetween: 20,
            },
            770: {
                slidesPerView: 2.25
            },
            870: {
                slidesPerView: 2.5,
                spaceBetween: 28,
            },
            930: {
                slidesPerView: 2.65
            },
            1024: {
                slidesPerView: 3
            },
            1150: {
                slidesPerView: 3
            },
            1260: {
                slidesPerView: 3
            },
            1360: {
                slidesPerView: 3
            },
            1440: {
                slidesPerView: 3,
                spaceBetween: 0,
            },
            1670: {
                slidesPerView: 3,
            },
            1800: {
                slidesPerView: 3,
            },
            1920: {
                slidesPerView: 4,
            }
        },
        className: "swiper-container product-page__slider-dots",
    }

    const [quoteSliderData] = sliders.filter(
        (slider) =>
            slider.node.type === "quote-slider" &&
            slider.node.location === `category-page ${title}`
    );

    const [cardBanner] = sliders.filter(
        (slider) =>
            slider.node.type === "cards" &&
            slider.node.location === "category-page"
    );

    const [roundImageBanner] = sliders.filter(
        (slider) =>
            slider.node.type === "product" &&
            slider.node.location === "homepage"
    );

    const [blogSliderData] = sliders.filter(
        (slider) =>
            slider.node.type === "blog-slider" &&
            slider.node.location === `category-page ${title}`
    );

    const [FAQBannerData] = banners.filter(
        (banner) =>
            banner.node.type === "faq-banner" &&
            banner.node.location === `category-page ${title}`
    );

    const getStyle = () => {
        let backgroundColor;
        switch (categoryCode) {
            case HAIR_LOST:
                backgroundColor = '#DDE4E5';
                break;
            default:
                backgroundColor = '#DDE4E5';
                break;
        }

        return backgroundColor ?
            `:root { --background-main: ${backgroundColor}; }` :
            '';
    }
    const ParentCategoryContent = () => {
        const { currency } = useCurrency();
        const { getCategoryName } = useCategories();
        const { getBrandName } = useBrands();

        const GTMData = {
            item_list_id: categoryId,
            item_list_name: title,
            items: [...categoryProducts.map((product, index) => {
                return {
                    item_id: product.bigcommerce_id,
                    item_name: product.name,
                    currency: currency.currency_code,
                    discount: 0,
                    index,
                    item_brand: getBrandName(product.brand_id),
                    item_category: getCategoryName(categoryId),
                    item_category2: getCategoryName(product.categories.find(id => id !== categoryId)),
                    item_variant: product.variants[0].id,
                    price: product.price,
                }
            })],
        }
        const productSelectClick = (productId) => {
            GTMSelectItemEvent({
                ...GTMData,
                items: [GTMData.items.find(item => item.item_id === productId)]
            });
        };

        useEffect(() => {
            isNotEmpty(categoryProducts) && isNotEmpty(currency) && GTMViewItemListEvent(GTMData);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [categoryProducts, currency]);

        return (
            <>
                <ParentCategoryHero categoryName={title}/>

                <div className="homepage__content category-page__content">

                    <ParentCategoryDropdown categoryName={title}/>
                    <FAQSlider sliderData={FAQSliderData[0]?.node} sliderConfig={FAQSliderConfig}/>

                    {isNotEmpty(categoryProducts) ? (
                        <ProductSlider
                            isHomepage
                            products={categoryProducts}
                            title={productsSliderData?.node?.title}
                            subtitle={productsSliderData?.node?.plainText}
                            productSelectClick={productSelectClick}
                        />
                    ) : (
                        <div className="empty-slider-placeholder">
                            There is no product yet
                        </div>
                    )}

                    <ParentCategoryDropdownChart categoryName={title}/>

                    <div className="category-page__cards">
                        <h2 className="typography__h2">
                            {cardBanner.node.sliderTitle}
                        </h2>
                        <div className="category-page__card-wrapper">
                            {cardBanner.node.slides.map((card, index) => (
                                <div className="category-page__card" key={index}>
                                    <div className="category-page__card-image">
                                        <Image image={card.slideImage} objectFit="contain"/>
                                    </div>
                                    <div className="category-page__card-text">
                                        <p className="title">{card.title}</p>
                                        <div className="subtext">
                                            <p>{card.subtext}</p>
                                        </div>
                                        <p className="text">{card.slideButton.text}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <RoundImageBanner bannerData={roundImageBanner.node}/>
                    {quoteSliderData && (
                        <QuoteSlider sliderData={quoteSliderData.node}/>
                    )}
                    <HomepageBottomBanner questionnaireLink={'/questionnaire-hair'}/>
                    {FAQBannerData && (
                        <ParentCategoryDropdownFAQ banner={FAQBannerData.node}/>
                    )}
                    {blogSliderData && (
                        <BlogSlider sliderData={blogSliderData.node}/>
                    )}
                </div>
            </>
        );
    }

    const gtmData = {
        page: {
            title,
            type: "Category",
        },
    };

    return (
        <Main promoBannerData={currentPromoBanner} gtmData={gtmData}>
            <HtmlHead title={categoryTitle} style={getStyle()}/>
            {categoryCode === TOP_PRODUCTS ? (
                <>
                    <StaticPageMeta pageName="Category all"/>
                    <h1 className="typography__h1 listpage__title">Onze producten</h1>
                    <ListpageContent/>
                </>
            ) : (
                <ParentCategoryContent/>
            )}
        </Main>
    );
};

export default Category;
