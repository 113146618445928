import React, { useState, useRef, useLayoutEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Dropdown from "../dropdown";
import Image from "components/UI/image";

export const categoryDropdownBannerChartQuery = graphql`
  query CategoryDropdownBannerChartQuery {
    allContentfulBannerUpdated(
      filter: {
        type: { eq: "dropdown-chart" }
      }
    ) {
      edges {
        node {
          title
          type
          location
          image {
            title
            file {
              url
              contentType
            }
            gatsbyImageData(
              placeholder: NONE,
              formats: [AUTO, WEBP, AVIF],
            )
          }
          bulletListText {
            id
            dropdownTitle
            text
            richText {
                raw
            }
          }
          text {
            raw
          }
        }
      }
    }
  }
`;

const ParentCategoryDropdownChart = (props) => {
    const { categoryName } = props;
    const queryData = useStaticQuery(categoryDropdownBannerChartQuery);
    const banners = queryData.allContentfulBannerUpdated.edges;
    const banner = banners.filter(banner => banner.node.location === `category-page ${categoryName}`)[0]?.node;
    const [height, setHeight] = useState(0);
    const dropdownBannerContentRef = useRef(null);

    useLayoutEffect(() => {
        setHeight(dropdownBannerContentRef.current?.clientHeight);
    }, [dropdownBannerContentRef.current?.clientHeight]);

    if (!banner) {
        return <></>;
    }

    const { title, text, image, bulletListText } = banner;

    return (
        <div ref={dropdownBannerContentRef} className="homepage__dropdown-banner category-page__dropdown dropdown-chart">
            <Image image={image} className="dropdown-chart__image desktop" style={{ height: `${height}px` }}
                   objectFit="contain"/>
            <div  className="homepage__dropdown-banner__content dropdown-chart__content">
                <h2 className="typography__h2 dropdown-chart__title">{title}</h2>
                <Image image={image} className="dropdown-chart__image mobile"/>
                {text && (
                    <div className="dropdown-chart__text">{renderRichText(text)}</div>
                )}
                <Dropdown items={bulletListText}/>
            </div>
        </div>
    );
};
export default ParentCategoryDropdownChart;
